import React, {useState} from 'react';

import ThorgateLogo from 'images/icons/thorgate-logo.svg';
import './Navbar.scss';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <nav className={`navbar navbar--${isOpen ? 'open' : 'closed'}`}>
            <div className="container navbar__container">
                <div className="navbar__left">
                    <a href="/" className="navbar__logo-link">
                        <ThorgateLogo className="navbar__logo" />
                    </a>
                </div>
                <button
                    className={`navbar__toggle hamburger hamburger--slider ${
                        isOpen ? 'is-active' : ''
                    }`}
                    onClick={() => setIsOpen(!isOpen)}
                    aria-label="Menu"
                    aria-controls="navigation"
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
                <ul id="navigation" className="navbar__items">
                    <li className="navbar__item">
                        <a
                            className="navbar__link"
                            href="https://thorgate.eu/about/"
                        >
                            About us
                        </a>
                    </li>
                    <li className="navbar__item">
                        <a
                            className="navbar__link"
                            href="https://thorgate.eu/services/"
                        >
                            Services
                        </a>
                    </li>
                    <li className="navbar__item">
                        <a
                            className="navbar__link"
                            href="https://thorgate.eu/tech-stack/"
                        >
                            Tech Stack
                        </a>
                    </li>
                    <li className="navbar__item">
                        <a
                            className="navbar__link"
                            href="https://thorgate.eu/blog/"
                        >
                            Blog
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
