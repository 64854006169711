import React from 'react';
import PropTypes from 'prop-types';

const BecomePartner = ({sectionContent}) => {
    return (
        <div className="section-gray">
            <div className="container ">
                <div className="row section-row">
                    <h1 className="col-lg-4">{sectionContent.heading}</h1>
                    <p className="col-lg-6">{sectionContent.content}</p>
                    <div className="col-lg-2 pl-4">
                        <a href="#form" className="orange-button">
                            Sign up
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

BecomePartner.propTypes = {
    sectionContent: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
    }).isRequired,
};

export default BecomePartner;
