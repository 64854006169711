import React from 'react';
import PropTypes from 'prop-types';

import WhatWeDoIcon from 'images/icons/icon-what-we-do.svg';
import WhyWeDoIcon from 'images/icons/icon-why-we-do.svg';
import HowWeDoIcon from 'images/icons/icon-how-we-do.svg';
import ExperienceIcon from 'images/icons/icon-experience.svg';
import ProjectsIcon from 'images/icons/icon-projects.svg';
import AwardsIcon from 'images/icons/icon-awards.svg';

const AboutUs = ({sectionContent}) => (
    <div className="section">
        <div className="container">
            <div className="row section-row">
                <h1 className="col-lg-4 col-md-5">{sectionContent.heading}</h1>
                <p className="col-lg-8 col-md-7">{sectionContent.content}</p>
            </div>
            <div className="row section-row">
                <div className="col-lg-4 col-md-4 d-flex">
                    <div>
                        <WhatWeDoIcon />
                    </div>
                    <div className="ml-3">
                        <p>
                            <strong>What we do</strong>
                        </p>
                        <div className="column-text">
                            <p>
                                We specialize in making processes efficient and
                                smart with technology.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex">
                    <div>
                        <WhyWeDoIcon />
                    </div>
                    <div className="ml-3">
                        <p>
                            <strong>Why we do it</strong>
                        </p>
                        <div className="column-text">
                            <p>
                                We love our partners and our goal is to help
                                them achieve their goals by increasing
                                efficency.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex">
                    <div>
                        <HowWeDoIcon />
                    </div>
                    <div className="ml-3">
                        <p>
                            <strong>How we do it</strong>
                        </p>
                        <div className="column-text">
                            <p>
                                We succeed because we “Think people, not
                                products”.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row section-row">
                <div className="col-lg-4 col-md-4 d-flex">
                    <div>
                        <ExperienceIcon />
                    </div>
                    <div className="ml-3">
                        <p>
                            <strong>Experience</strong>
                        </p>
                        <div className="column-text">
                            <p>
                                We as company and team hold 8+ years experience
                                in the industry.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex">
                    <div>
                        <ProjectsIcon />
                    </div>
                    <div className="ml-3">
                        <p>
                            <strong>Projects</strong>
                        </p>
                        <div className="column-text">
                            <p>
                                We have more than 150 diverse projects of
                                digital transformations under our belt.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 d-flex">
                    <div>
                        <AwardsIcon />
                    </div>
                    <div className="ml-3">
                        <p>
                            <strong>Awards</strong>
                        </p>
                        <div className="column-text">
                            <p>
                                We only deliver quality work and this is why we
                                have 20+ awards on some our best projects.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

AboutUs.propTypes = {
    sectionContent: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
    }).isRequired,
};

export default AboutUs;
