import React from 'react';
import {SEO} from 'components';
import {useStaticQuery, graphql} from 'gatsby';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Intro from 'components/Home/Intro';
import {SubmissionForm} from 'components/Home/SubmissionForm';
import AboutUs from 'components/Home/AboutUs';
import BecomePartner from 'components/Home/BecomePartner';
import Quotes from 'components/Home/Quotes';
import HaveQuestions from 'components/Home/HaveQuestions';

import 'components/Home/Home.scss';

const Home = () => {
    const data = useStaticQuery(graphql`
        query PageContentQuery {
            headerImage: file(relativePath: {eq: "header-image.png"}) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            allFile(filter: {name: {eq: "home"}}) {
                edges {
                    node {
                        id
                        childMarkdownRemark {
                            frontmatter {
                                about {
                                    heading
                                    content
                                }
                                partner {
                                    heading
                                    content
                                }
                                quotes {
                                    author
                                    quote
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const pageContent =
        data.allFile.edges[1].node.childMarkdownRemark.frontmatter;

    const quotesList = pageContent.quotes;

    return (
        <>
            <SEO
                title="Thorgate Affiliate Program"
                description="Join us in changing the world with technology"
            />
            <Navbar />
            <Intro headerImage={data.headerImage} />
            <SubmissionForm />
            <AboutUs sectionContent={pageContent.about} />
            <BecomePartner sectionContent={pageContent.partner} />
            <Quotes quotesList={quotesList} />
            <HaveQuestions />
            <Footer />
        </>
    );
};

export default Home;
