import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Arrow from 'images/icons/arrow.svg';

const Intro = ({headerImage}) => {
    return (
        <div className="section">
            <div className="container">
                <div className="row section-row">
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center">
                        <h1>Thorgate Affiliate Program</h1>
                        <p>Join us in changing the world with technology</p>
                        <a href="#form" className="orange-button">
                            Sign up
                        </a>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <Img fluid={headerImage.childImageSharp.fluid} />
                    </div>
                </div>
                <div className="row section-row d-flex justify-content-end">
                    <div className="col-lg-9 col-md-8 col-sm-12">
                        <h2>
                            Do you know companies searching for{' '}
                            <span>development partners</span> to increase
                            efficiency?
                        </h2>
                    </div>
                </div>
                <div className="connecting-arrow">
                    <Arrow />
                </div>
            </div>
        </div>
    );
};

Intro.propTypes = {
    headerImage: PropTypes.object.isRequired,
};

export default Intro;
